.testimonial_box {
  padding-top: 3rem;
}

.iframeTestComp{
  border-radius: 3px;
    border: 3px solid #ef6603;
}

.card {
  width: 50%;
  display: flex;
  margin-top: 4rem;
}

.card p {
  font-style: italic;
  margin-top: 1.5rem;
  margin-left: 1.5rem;
  font-size: 20px;
  font-weight: 600;
}

.card h4 {
  opacity: 0.5;
  margin-left: 1.5rem;
  margin-top: -7px;
  padding-bottom: 1rem;
}

.student {
  width: 100%;
  height: 55vh;
}

.slick-slide > div {
  margin: 0 10px;
}

@media (max-width: 1368px) {
}
