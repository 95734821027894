*::before {
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  font-size: 1.6rem;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: darkgray;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: #333;
}

.logo {
  background-size: cover;
  width: 90%;
  height: auto;
}

.nav {
  display: flex;
  /* overflow: hidden; */
  align-items: center;
  justify-content: space-around;
  height: 70px;
  background: #303345;
  transition: background-color 0.3s;
}

.nav__brand {
  text-decoration: uppercase;
  font-weight: bold;
  font-size: 28px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
  color: white;
  font-size: 24px;
  display: flex;
  width: 25%;
  text-decoration: none;
}

.nav__menu {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 15px;
}

.nav__link {
  color: aliceblue;
  font-size: 1.5rem;
  text-decoration: none;
  padding: 5px 15px 5px 15px;
}

.nav__link:hover {
  background-color: #ef6603;
  color: #f8f8f8;
  border-radius: 5px;
  padding: 10px 20px;
}

.nav__toggler div {
  width: 2.5rem;
  height: 0.2rem;
  margin: 0.4rem;
  background: darkgray;
  transition: 0.4s ease-in;
}

.nav__toggler {
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 800px) {

  .logo{
    width: 230px;
    height: auto;
    margin-left: -10px;
  }
  .nav ul {
    background-color: #303345;
    position: fixed;
    height: 100vh;
    width: 50%;
    overflow: visible;
    z-index: 20000;
    font-size: 18px;
  }
  .nav__toggler {
    display: block;
    margin-left: 150px;
  }
  .nav__menu {
    position: fixed;
    top: 6vh;
    right: 0;
    width: 50%;
    height: 100vh;
    background: #303345;
    flex-direction: column;
    transform: translateX(100%);
    transition: 0.5s ease-in;
    padding-top: 30px;
  }
  .nav__link{
    font-size: 18px;
    padding: 5px 15px 5px 0px;
  }
  .nav__item{
    margin-top:15px;
  }
}

.nav__active {
  transform: translateX(0);
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-4px, 5px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-4px, -5px);
}
