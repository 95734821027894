.service_box,
.service_box_second {
  display: flex;
  margin-top: 30px;
}

.service_box1,
.service_box2 {
  display: flex;
  border-radius: 7px;
  border: 1px solid #eeeeee;
}

.service_box2 {
  margin-left: 3rem;
}

.social_image {
  font-size: 44px;
}

.social_content {
  padding: 25px 20px 10px 15px
}

.social_content h3 {
  /* margin-top: 20px; */
  font-size: 22px;
  font-weight: 700;
}

.social_content p {
  font-size: 14px;
}

.image_box {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.benefit_img {
  width: 90px;
  padding-left: 10px;
}

.service_box_second {
  margin-top: 40px;
}

@media screen and (max-width: 800px) {
  .service_box {
    display: block;
    height: 50vh;
  }
  .service_box2 {
    margin-top: 2rem;
    margin-left: 0rem;
  }
  .service_box_second {
    margin-top: 0rem;
    display: block;
    height: 60vh;
  }
}
@media only screen and (min-width: 375px) and (max-width: 667px) {
  .service_box_second {
    margin-top: 10rem;
  }
}

@media only screen and (min-width: 390px) and (max-width: 884px) {
  .service_box_second {
    margin-top: 2rem;
  }
}
