.team_box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
}

.team_box1 {
  height: 100%;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.team_box2 {
  height: 100%;
  margin-left: 7rem;
}

.img_team {
  height: 300px;
  width: 100%;
}

.insta {
  height: 40px;
  width: 40px;
  background-color: #ef6603;
  color: #fff;
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.insta:hover {
  background-color: #f08c45;
}

.p_name {
  padding-left: 2rem;
  padding-top: 2rem;
  font-size: 20px;
  font-weight: 600;
  /* font-style: italic; */
}

.p_post {
  padding-left: 2rem;
  margin-top: -1rem;
  font-size: 13px;
  color: #7c91a2;
}

@media (max-width: 800px) {
  /* @media screen (max-width: 600px) and (min-width: 1368px) { */
  .team_box {
    display: block;
  }
  .team_box1 {
    margin-top: 0rem;
  }
  .team_box2 {
    margin-top: 2rem;
    margin-left: 0rem;
    padding-bottom: 8px;
  }
}

/* .card {
  width: 50%;
  display: flex;
  margin-top: 4rem;
}

.card p {
  font-style: italic;
  margin-top: 1.5rem;
  margin-left: 1.5rem;
  font-size: 20px;
  font-weight: 600;
}

.card h4 {
  opacity: 0.5;
  margin-left: 1.5rem;
  margin-top: -7px;
  padding-bottom: 1rem;
}

.student {
  width: 100%;
  height: 55vh;
}

.slick-slide > div {
  margin: 0 10px;
}
 */
