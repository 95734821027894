.hero__slider,
.hero__slider2,
.hero__slider3 {
  background-size: cover;
  height: 600px;
  display: block;
  justify-content: center;
}

a{
  text-decoration: none !important;
}

.hero__slider {
  padding-top: 6rem;
  background-image: url("../images/banner1.jpg");
  box-shadow: inset 0 0 0 2000px rgba(3, 3, 3, 0.3);
}

.hero__slider2 {
  padding-top: 6rem;
  background-image: url("../images/banner5.avif");
  box-shadow: inset 0 0 0 2000px rgba(9, 9, 9, 0.5);
}

.hero__slider3 {
  padding-top: 6rem;
  background-image: url("../images/banner3.jpg");
  box-shadow: inset 0 0 0 2000px rgba(9, 9, 9, 0.6);
}

.hero__slider h5 {
  text-align: center;
  color: white;
  margin-top: 8px;
  font-size: 14 px;
}

.hero__slider2 h5 {
  text-align: center;
  color: white;
  margin-top: 8px;
  font-size: 14 px;
}

.hero__slider3 h5 {
  text-align: center;
  color: white;
  margin-top: 8px;
  font-size: 14 px;
}

.slider__content1 {
  display: block;
  text-align: center;
  align-items: center;
  font-size: 2rem;
  color: #f8f8f8;
  padding-top: 2px;
}

.slider__content1 a {
  font-weight: bold;
}

.slider__content1 p {
  margin-top: 40px;
  font-size: 20px;
}

.slider__content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  color: #f8f8f8;
  padding-top: 20px;
}

.inner__slider {
  display: flex;
  font-size: 5rem;
  font-weight: 700;
  justify-content: center;
  padding-top: 6rem;
  color: #ef6603;
}

.btn_slider {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.slider_btn {
  display: flex;
  justify-content: center;
  background-color: #ef6603;
  border: 0px solid #ef6603;
  padding: 10px 25px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 7px;
  color: #f8f8f8;
}

.slider_btn:hover {
  background-color: #e65100;
}

@media screen and (max-width: 768px) {
  .hero__slider {
    padding-top: 3rem;
  }
  .inner__slider {
    font-size: 3rem;
    padding-top: 5rem;
  }
  .inner__slider {
    font-size: 28px;
  }
  .slider__content1 a {
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
  }
  .slider__content1 p {
    padding-top: 2rem;
    text-align: center;
    font-size: 16px;
  }
  .slider__content {
    padding-top: 2rem;
    text-align: center;
    font-size: 16px;
  }
  .hero__slider {
    height: 66vh;
  }
  .hero__slider2 {
    padding-top: 9rem;
    height: 66vh;
  }
  .hero__slider3 {
    padding-top: 8rem;
    height: 66vh;
  }
}
