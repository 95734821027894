.eligibility_box {
  display: flex;
  padding-bottom: 6rem;
}

.eligibility_box_inner2 {
  font-size: 16px;
  display: block;
  margin-left: 8rem;
}

.eligibility_box_inner2 p {
  font-size: 16px;
}

.eligibility_img {
  width: 100% ;
  border-radius: 5px;
  margin-top: 10px;
}

.arrow_icon {
  color: #ef6603;
  font-size: 20px;
}

.pilot {
  text-align: center;
  height: 20vh;
}

.pilot_img2 {
  width: 100%;
  border-radius: 5px;
}

@media (max-width: 800px) {
  .eligibility_box {
    display: block;
    padding-bottom: 0px;
  }
  .eligibility_box_inner {
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 0rem;
  }
  .eligibility_box_inner2 {
    font-size: 1.5rem;
    display: block;
    margin-left: 0rem;
  }
  .eligibility_img {
    height: 23vh;
    border-radius: 5px;
  }
  .pilot {
    text-align: center;
    height: 10vh;
    margin-bottom: 12rem;
  }
  .pilot_img2 {
    width: 100%;
    height: 31vh;
  }
}
