.label-1 {
  display: flex;
}

.label-1 h4 {
  opacity: 0.6;
  font-weight: 600;
}

.label-1 hr {
  margin-top: 4rem;
  margin-left: 1rem;
  border: 1.5px solid #ff4f03;
  height: 1px;
  width: 8%;
}

.label-2 {
  font-size: 36px;
  font-weight: 700;
}

@media screen and (max-width: 800px) {
  .label-1 hr {
    width: 25%;
  }
  .container{
    width: 90% !important;
  }
}
